import React from "react"
import styled from "styled-components"

const FooterSmall = () => (
  <FooterWrapper id="footer">
    © FinRin.com 2020, All Rights Reserved.
  </FooterWrapper>
)

export default FooterSmall

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.background.white};
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 4vh;
  padding-bottom: 4vh;
`
